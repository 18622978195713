import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Impressum = () => (
  <Layout>
    <Seo title="Impressum" />
    <div className="font-courier text-justify">
      <h1 className="text-xl font-bold my-4 underline">Impressum:</h1>
      <h2 className="text-lg my-4">Verantwortlich im Sinne des § 5 TMG:</h2>
      <p className="py-4">
        Valentin Diem <br />
        Hochgernstraße 16 <br />
        83224 Grassau
      </p>
      <h2 className="text-lg my-4">Kontakt:</h2>
      <p>E-Mail: info@bildhauer-vale.de</p>
      <h2 className="text-lg my-4">Haftungsausschluss: </h2>
      <h3 className="my-2 underline">Haftung für Inhalte</h3>
      <p className="py-4">
        Die Inhalte meiner Seiten wurden mit größter Sorgfalt erstellt. Für die
        Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich jedoch
        keine Gewähr übernehmen. Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG
        für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Nach §§ 8 bis 10 TMG bin ich als Diensteanbieter jedoch
        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
        zu überwachen oder nach Umständen zu forschen, die auf eine
        rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
        bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
        ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde ich diese
        Inhalte umgehend entfernen.
      </p>
      <h3 className="my-2 underline">Haftung für Links</h3>
      <p className="py-4">
        Mein Angebot enthält Links zu externen Webseiten Dritter, auf deren
        Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werde ich derartige Links umgehend entfernen.
      </p>
      <h3 className="my-2 underline">Urheberrecht</h3>
      <p className="py-4">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitte ich um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde
        ich derartige Inhalte umgehend entfernen.
      </p>
      <h3 className="my-2 underline">Datenschutz</h3>
      Die Nutzung meiner Webseite ist in der Regel ohne Angabe personenbezogener
      Daten möglich. Soweit auf meinen Seiten personenbezogene Daten
      (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden,
      erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten
      werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
      Ich weise darauf hin, dass die Datenübertragung im Internet (z.B. bei der
      Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
      lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
      möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
      Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
      angeforderter Werbung und Informationsmaterialien wird hiermit
      ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
      ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
      Werbeinformationen, etwa durch Spam-Mails, vor.
    </div>
    <div className="my-8" />
  </Layout>
)
export default Impressum
